import React from "react"

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"
import Link from "gatsby-link"
import Hero from "../images/gdpr/data-privacy-cover.svg"
import "./dataprivacy.scss"
import VerticalCarousel from "../components/vertical-carousel/vertical-carousel"
import RebrandCarousel from "../components/rebrand-carousel/rebrand-carousel"
import security from "../images/gdpr/security-carousel.svg"
import technology from "../images/gdpr/tech-carousel.svg"
import control from "../images/gdpr/control-carousel.svg"
import gdpr from "../images/gdpr/gdpr-carousel.svg"
import transparency from "../images/gdpr/transparency-carousel.svg"
import policies from "../images/gdpr/policies-carousel.svg"

const GDPR = () => {
  const carouselDataGDPR = [
    {
      title: "Security",
      desc: "We have carefully designed information security practices and policies that ensure data security at all times. Microsoft Azure cloud technology provides a secure foundation across physical, infrastructure and operational systems. Only relevant personnel in SHAPE have access to customer data ensuring we act as a responsible data processor. Our standard operating procedures include regular personnel training, ongoing assessments and regular audits alongside robust documentation controls to prevent a data breach.",
      contentImg: security,
      color: "green",
      tab: "Security",
    },
    {
      title: "Technology",
      desc: "SHAPE employs state-of-the-art technology, allowing employees to confidently and securely answer survey questions and confidentially view their results. Microsoft Azure cloud technology ensures access to the latest, enterprise-grade security, practices and performance. For additional security, we employ data pseudonymisation and encryption technologies – both in transit and at rest. We fully document and control the use of algorithms and employ extensive test methodologies to ensure data accuracy and security.",
      contentImg: technology,
      color: "green",
      tab: "Technology",
    },
    {
      title: "Full Control",
      desc: "SHAPE operates in a way that gives its users complete control. For our diverse reader populations, we operate a clear language policy. We empower you with rich knowledge and documentation to safely engage your employees about SHAPE. You and your employees always have the right to view and rectify data under Article 15 and 16 of GDPR (General Data Protection Legislation). We offer complete access to updated legal and contractual documents.",
      contentImg: control,
      color: "green",
      tab: "Control",
    },
    {
      title: "GDPR Compliance",
      desc: "Our goal remains ongoing compliance with GDPR, which we actively understand and align with. All data is owned by the employees who provide it, so they have complete control over what happens to it. SHAPE ensures continuous data anonymisation and collects explicit consent where options exist to waive anonymity. We operate clear and defined processes for data management, including account and data deletion under rules for the transfer of data internationally.",
      contentImg: gdpr,
      color: "green",
      tab: "GDPR",
    },
    {
      title: "Transparency and Consent",
      desc: 'SHAPE\'s communicates openly. We explicitly ask for consent and maintain records of it. We never share data without permission. Our <a href="/privacy">Privacy Policy</a> is always accessible. Requests for data deletion are always honoured as part of the right to be forgotten. We operate an updated <a href="/#faqs">Frequently Asked Questions</a> section to empower improved understanding of our service and the rights of our customers.',
      contentImg: transparency,
      color: "green",
      tab: "Transparency",
    },
    {
      title: "Policies",
      desc: "SHAPE's policies are in line with the UK Information Commissioner's Office (ICO), and regular privacy assessments are performed as part of our product development and audit processes. We also carry out regular data, system and security audits. We classify all data, which allows us to manage sensitive and personal data. All our business practices, including staff training, are documented in the form of detailed Standard Operating Procedures. We continuously review international regulations to ensure further compliance with country laws where we operate.",
      contentImg: policies,
      color: "green",
      tab: "Policies",
    },
  ]
  return (
    <Layout>
      <SEO title="Data Privacy" />
      <div className="heroDp">
        <Container className="container-custom-privacy">
          <Row>
            <Col xs={{ order: 1, span: 12 }} sm={{ order: 1, span: 6 }}>
              <div className="flexCenter">
                <h1>Data Privacy and Compliance</h1>
                <p>
                  At SHAPE, we have your trust at the heart of our service. We
                  protect your data and align with data privacy laws at every
                  step of our service.
                </p>
              </div>
            </Col>
            <Col xs={{ order: 12, span: 12 }} sm={{ order: 12, span: 6 }}>
              <div className="imgMarginSmall">
                <img src={Hero} alt="Data Privacy" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contentDp">
        <section>
          <Container className="container-custom-content">
            <div className="marginBottom">
              <h3>How SHAPE Protects You</h3>
              <RebrandCarousel data={carouselDataGDPR} dataPrivacy={true} />
            </div>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default GDPR
